import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useBannerList() {
  const toast = useToast()

  const fetchBanner = async () => {
    try {
      const response = await store.dispatch('app-banner/fetchBanner');
      const { data } = response.data;
      return data;
    } catch(error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching banner',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const updateBanner = async (banner) => {
    try {
      const response = await store.dispatch('app-banner/updateBanner', { ...banner });
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error update banner',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  const uploadImage = async (images) => {
    try {
      const response = await store.dispatch('app-banner/uploadImage', images);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error update banner',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  return {
    fetchBanner,
    updateBanner,
    uploadImage,
  }
}
