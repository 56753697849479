<template>
  <div>
    <validation-observer ref="form">
      <b-form>
        <b-card>
          <b-row>
            <b-col sm="12">
              <h3>Media</h3>
            </b-col>
            <b-col sm="12">
              <b-form-group>
                <label for="type">Media Type <span class="text-red">*</span></label>
                <b-form-radio-group
                  v-model="type"
                  @change="typeChange"
                >
                  <b-form-radio value="Image">
                    Image
                  </b-form-radio>
                  <b-form-radio value="Video">
                    Video
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-row>
                <b-col sm="12">
                  <div 
                    class="flex flex-col preview-image dashed"
                    :hidden="fileUrl !== ''"
                    id="dropzone"
                    :class="loading ? 'pointer-none' : ''"
                  >
                    <div
                      class="flex flex-col items-center"
                      @click="$refs.file.$refs.input.click()"
                    >
                      <feather-icon
                        icon="UploadCloudIcon"
                        size="30"
                      />
                      <span class="font-18px">Select your image</span>
                      <span>Recommened dimension : 1440x740 px</span>
                      <span>Image: Max 300 MB (PNG, JPG, MP4)</span>
                    </div>
                  </div>
                  <b-img
                    v-if="type === 'Image' && fileUrl !== ''"
                    :src="fileUrl"
                    class="image-full cursor-pointer image-preview"
                    @click="$refs.file.$refs.input.click()"
                  />
                  <video
                    v-if="type === 'Video' && fileUrl !== ''"
                    width="320"
                    controls
                  >
                    <source :src="fileUrl">
                  </video>
                </b-col>
                <b-col sm="12" class="mt-12px">
                  <b-form-group>
                    <label for="banner-image">Image Upload <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      ref="image"
                      name="image"
                      rules="required"
                    >
                      <b-form-file
                        ref="file"
                        :disabled="loading"
                        v-model="file"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Choose another file"
                        accept=".jpg, .png, .mp4"
                        @input="inputImageRenderer"
                        :class="loading ? 'loading-form-file': ''"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col sm="12">
              <h3>Secondary Image</h3>
            </b-col>
            <b-col sm="12">
              <b-row>
                <b-col sm="12">
                  <div 
                    class="flex flex-col preview-image dashed"
                    :hidden="subfileUrl"
                    id="dropzone-subfile"
                    :class="loading ? 'pointer-none' : ''"
                  >
                    <div
                      class="flex flex-col items-center"
                      @click="$refs.subfile.$refs.input.click()"
                    >
                      <feather-icon
                        icon="UploadCloudIcon"
                        size="30"
                      />
                      <span class="font-18px">Select your image</span>
                      <span>Recommened dimension : 1440x740 px</span>
                      <span>Image: Max 300 MB (PNG, JPG, MP4)</span>
                    </div>
                  </div>
                  <b-img
                    :src="subfileUrl"
                    class="image-full cursor-pointer secondary-image-preview"
                    @click="$refs.subfile.$refs.input.click()"
                  />
                </b-col>
                <b-col sm="12" class="mt-12px">
                  <b-form-group>
                    <label for="banner-image">Image Upload <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      ref="secondary-image"
                      name="secondary-image"
                      rules="required"
                    >
                      <b-form-file
                        ref="subfile"
                        :disabled="loading"
                        v-model="subfile"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Choose another file"
                        accept=".jpg, .png, .mp4"
                        @input="inputSubImageRenderer"
                        :class="loading ? 'loading-form-file': ''"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-row>
            <b-col sm="12">
              <h3>Content</h3>
            </b-col>
            <b-col sm="12">
              <b-tabs pills>
                <b-tab title="English" active>
                  <b-form-group>
                    <label for="content-banner-name">Banner Name <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="banner name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="english.name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Banner name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group>
                    <label for="content-banner-note">Note</label>
                    <b-form-textarea
                      v-model="english.note"
                      rows="5"
                      placeholder="Note about this Banner"
                    />
                  </b-form-group>
                </b-tab>
                <b-tab title="Thai">
                  <b-form-group>
                    <label for="content-banner-name">Banner Name</label>
                    <b-form-input
                      v-model="thai.name"
                      placeholder="Banner name"
                    />
                  </b-form-group>
                  <b-form-group>
                    <label for="content-banner-note">Note</label>
                    <b-form-textarea
                      v-model="thai.note"
                      rows="5"
                      placeholder="Note about this Banner"
                    />
                  </b-form-group>
                </b-tab>
                <b-tab title="Chinese">
                  <b-form-group>
                    <label for="content-banner-name">Banner Name</label>
                    <b-form-input
                      v-model="chinese.name"
                      placeholder="Banner name"
                    />
                  </b-form-group>
                  <b-form-group>
                    <label for="content-banner-note">Note</label>
                    <b-form-textarea
                      v-model="chinese.note"
                      rows="5"
                      placeholder="Note about this Banner"
                    />
                  </b-form-group>
                </b-tab>
              </b-tabs>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>

    <div class="fixed-footer justify-content-end z-10">
      <b-button
        variant="outline-secondary"
        @click="() => this.$router.go(0)"
      >
        <span>Cancel</span>
      </b-button>
      <b-button
        class="ml-1"
        variant="success"
        type="submit"
        :disabled="loading"
        @click.prevent="validationForm"
      >
        <span>Save</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import Dropzone from 'dropzone'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BFormRadioGroup, BFormRadio, BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BTab, BTabs, BFormTextarea } from 'bootstrap-vue'
import { required, email, regex } from '@validations'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import bannerStoreModule from './bannerStoreModule'
import useBanner from './useBanner'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BFormRadioGroup,
    BFormRadio,
    BButton,
    BForm,
    BImg,
    BFormTextarea,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BTab,
    BTabs,

    vSelect,
  },
  data() {
    return {
      required,
      regex,
      email,
      loading: false,
      file: null,
      subfile: null,
      fileUrl: '',
      subfileUrl: '',
      type: 'Image',
      thai: {
        name: '',
        note: '',
      },
      english: {
        name: '',
        note: '',
      },
      chinese: {
        name: '',
        note: '',
      },
    }
  },
  methods: {
    typeChange() {
      this.file = null
      this.fileUrl = ''
    },
    async inputImageRenderer(file) {
      if (!file) return;
      if ((this.file.size / 1024 / 1024) > 300) {
        await this.$refs.image.validate()
        await this.$refs.image.setErrors(['The file size must not exceed the specified size'])
        return;
      }


      const form = new FormData();
      form.append('files', file);
      
      this.loading = true;
      const { data, status } = await this.uploadImage(form);

      if (status !== 200) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Error upload image',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return;
      }

      this.fileUrl = data[0];
      this.loading = false;
    },
    async inputSubImageRenderer(file) {
      if (!file) return;
      if ((this.file.size / 1024 / 1024) > 300) {
        await this.$refs.subfile.validate()
        await this.$refs.subfile.setErrors(['The file size must not exceed the specified size'])
        return;
      }


      const form = new FormData();
      form.append('files', file);
      
      this.loading = true;
      const { data, status } = await this.uploadImage(form);

      if (status !== 200) {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Error upload image',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return;
      }

      this.subfileUrl = data[0];
      this.loading = false;
    },
    async validationForm() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      if ((this.file.size / 1024 / 1024) > 300) {
        await this.$refs.image.validate()
        await this.$refs.image.setErrors(['The file size must not exceed the specified size'])
        return;
      }

      const videoExts = ['mp4'];
      const imageExts = ['jpg', 'png'];
      const extension = this.fileUrl.substr(this.fileUrl.lastIndexOf('.') + 1);
      const isMediaTypeValid = (this.type === 'Image' && imageExts.includes(extension)) || this.type === 'Video' && videoExts.includes(extension);
      if (!isMediaTypeValid) {
        this.$refs.image.setErrors(['Format file is invalid']);
        return;
      }

      const { status } = await this.updateBanner({
        mediaUrl: this.fileUrl,
        mediaType: this.type,
        secondaryImageUrl: this.subfileUrl,
        english: this.english,
        thai: this.thai,
        chinese: this.chinese,
      })
      if (status !== 200) {
        this.makeToast(false)
        return;
      }

      this.makeToast(true)
    },
    makeToast(success) {
      this.$bvToast.toast(
        (
          <div class="d-flex">
            <feather-icon
              class={success ? 'text-success' : 'text-danger'}
              icon={success ? 'CheckCircleIcon' : 'XCircleIcon'}
              size="24"
            />
            <div class="d-flex flex-column">
              <h4 class={success ? 'ml-1 text-success' : 'ml-1 text-danger'}>{success ? 'Update succeeded!' : 'Update failed!' }</h4>
              <div class="ml-1 font-14px">{success ? `You has been updated “${this.english.name}” Banner.` : 'Can not update banner.'}</div>
            </div>
          </div>
        ),
        {
          headerClass: 'd-none',
          solid: true,
        },
      )
    },
  },
  async created() {
    const app = document.getElementById('app')
    const appLoading = document.getElementById('loading-bg')
    const appLoadingImage = document.querySelector('#loading-bg > img')

    if (appLoading) {
      app.style.overflow = 'hidden'
      appLoading.style.zIndex = '9'
      appLoading.style.display = 'block'
      appLoadingImage.src = `/loading.gif?${Math.random()}`
    }

    try {
      const banner = await this.fetchBanner();
      this.english = banner.english;
      this.chinese = banner.chinese;
      this.thai = banner.thai;
      this.type = banner.mediaType;
      this.fileUrl = banner.mediaUrl;
      this.subfileUrl = banner.secondaryImageUrl;
      this.file = new File([''], 'banner.jpg');
      this.subfile = new File([''], 'banner.jpg');
      if (appLoading) {
        app.style.overflow = 'auto'
        appLoading.style.display = 'none'
      }
    } catch (error) {
      this.toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching banner',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  },
  mounted() {
    const dropzoneElement = document.getElementById('dropzone')
    if (dropzoneElement) {
      const dropZone = new Dropzone(dropzoneElement, { url: '/', autoProcessQueue: false, disablePreviews: true });
      dropZone.on('addedfile', async (file) => {
        this.$refs.file.setFiles([file])
      })
    }

    const dropzoneSubElement = document.getElementById('dropzone-subfile')
    if (dropzoneSubElement) {
      const dropZone = new Dropzone(dropzoneSubElement, { url: '/', autoProcessQueue: false, disablePreviews: true });
      dropZone.on('addedfile', async (file) => {
        this.$refs.subfile.setFiles([file])
      })
    }
  },
  setup() {
    const BANNER_STORE_MODULE_NAME = 'app-banner'

    // Register module
    if (!store.hasModule(BANNER_STORE_MODULE_NAME)) store.registerModule(BANNER_STORE_MODULE_NAME, bannerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(BANNER_STORE_MODULE_NAME)) store.unregisterModule(BANNER_STORE_MODULE_NAME)
    })

    const { fetchBanner, updateBanner, uploadImage } = useBanner();
    const toast = useToast()

    return {
      toast,
      fetchBanner,
      updateBanner,
      uploadImage,
    }
  },
}
</script>

<style scoped>
.z-10 {
  z-index: 10;
}
.image-preview {
  max-width: 640px;
  max-height: 320px;
}
.secondary-image-preview {
  max-width: 180px;
}
</style>
